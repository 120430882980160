import ApiService from "@/core/services/ApiService";
import { Members } from "@/core/services/responsesModel/members"
import { UserExtend } from "@/core/services/responsesModel/userExtend"

// 取得空間使用者
export function getMembersBySpace(spaceGuid: string): Promise<Array<Members>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Members`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增空間使用者
export function addMember(request): Promise<UserExtend> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Members/AddByManager`, request).then(res => {
            resolve(res.data.user)
        }).catch(err => {
            reject(err)
        })
    })
}

// 移除空間使用者
export function removeSpaceMember(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Members/RemoveSpace`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 查詢simpleUsers
export function getSimpleUsers(request): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Members/QuerySimple`, request).then(res => {
            resolve(res.data.simpleUsers)
        }).catch(err => {
            reject(err)
        })
    })
}