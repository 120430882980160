
import { defineComponent, reactive, ref,provide } from "vue";
import {
    getAccessGroup,
    deleteAccessGroup,
    
} from "@/core/services/api/accessGroup";
import{
  DeaultAccessGroup,
  AccessGroup
} from "@/core/services/responsesModel/accessGroup";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { ConfirmCanncelAlert, SuccesAlert, ErrorAlert } from "@/core/utils/customSwal"
import AccessGroupUserDialog from "./components/AccessGroupUserDialog.vue"
import AddAccessGroupUserDialog from "./components/AddAccessGroupUserDialog.vue"

export default defineComponent({
    components: {
        Datatable,
        AccessGroupUserDialog,
        AddAccessGroupUserDialog 
    },
    setup() {
        const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
        const currentAccessGroup = ref<AccessGroup>(DeaultAccessGroup);
        const tableData: Array<AccessGroup> = reactive([]);
        const initTableData: Array<AccessGroup> = reactive([]);
        
        const isLoading = ref(false);
        const accessGroupDialogVisiable = ref(false);
        const tableHeader: any = reactive([
            { key: "name", name: "名稱" },
            { key: "accessDevices", name: "通行裝置名稱", sortable: true },
            { key: "users", name: "已設定人數", sortable: true },
            { key: "operate", name: "操作" }
        ]);
        const search = ref<string>("");
        const tableSearch = () => {
            tableData.splice(0, tableData.length, ...initTableData);
            tableData.splice(
                0,
                tableData.length,
                ...searchItems(search.value, tableData)
            );
        };
        provide("currentAccessGroup", currentAccessGroup);
        const setcurrentAccessGroup = (item) => {
          currentAccessGroup.value=item;
        };

        const removeAccessGroup = async () => {
          
          ConfirmCanncelAlert("您確定要刪除通行裝置群組嗎?",).then(async (result) => {
                if (result.isConfirmed) {
                    const result=await deleteAccessGroup(
                        currentSpaceGuid!,
                        currentAccessGroup.value.guid
                    );
                    if(result){
                      await init();
                      SuccesAlert("刪除成功!");
                    }else{
                      ErrorAlert("刪除失敗!");
                    }
                }
            });
        };

        const setTableData = async () => {
            isLoading.value = true;
            let accessGroups = await getAccessGroup(currentSpaceGuid!);
            tableData.splice(0, tableData.length, ...accessGroups);
            isLoading.value = false;
        };
        const openAccessGroupUserDialog= (item) => {

          accessGroupDialogVisiable.value=true;
          currentAccessGroup.value = item;
        }
        const addAccessGroupUserDialogVisiable = ref(false);
        const handleAddUserClick=()=>{
          accessGroupDialogVisiable.value = false;
          addAccessGroupUserDialogVisiable.value=true;

        }
        const init = async () => {
            await setTableData();
            
        };
        init();
        const reload=async()=>{
          await init();
          currentAccessGroup.value = tableData.filter(o=>o.guid==currentAccessGroup.value.guid)[0]
        }

        return {
            tableData,
            tableHeader,
            initTableData,
            tableSearch,
            search,
            isLoading,
            setcurrentAccessGroup,
            currentAccessGroup,
            removeAccessGroup,
            accessGroupDialogVisiable,
            openAccessGroupUserDialog ,
            addAccessGroupUserDialogVisiable,
            reload,
            handleAddUserClick
        };
    },
});
