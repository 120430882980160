
export enum UserType {
  SystemUser = "SystemUser",
  WebUser = "WebUser"
}

export enum UserIdentityType {
  CloudMatrixUser = "CloudMatrixUser",
  FaceVideo = "FaceVideo",
  FaceImage = "FaceImage",

}

export class UserIdentity {
    guid: string;
    userType: UserType;
    user: string;
    identityType: UserIdentityType;
    identity:string;
    createTime: string;
    thirdPartyId: string;
    

    constructor(data:any){
      this.guid = data.guid;
      this.userType = data.userType;
      this.user = data.user;
      this.identityType = data.identityType;
      this.identity= data.identity;
      this.createTime = data.createTime;
      this.thirdPartyId = data.thirdPartyId;
    }
}