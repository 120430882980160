
export enum VendorType {
  Viainno = "Viainno",
  CloudMatrix = "CloudMatrix"
}
export enum AccessDoorMethod {
  SpacesSystem = "SpacesSystem",
  ThirdParty = "ThirdParty"
}

export class AccessDevice {
    guid: string;
    space: string;
    vendor: VendorType;
    deviceId:string;
    name: string;
    method: AccessDoorMethod;
    systemDevice: string;
    createTime: string;

    constructor(data:any){
      this.guid = data.guid;
      this.space = data.space;
      this.vendor = data.vendor;
      this.deviceId = data.deviceId;
      this.name = data.name;
      this.method = data.method;
      this.systemDevice = data.systemDevice;
      this.createTime = data.createTime;
    }
}