<template>
  <Dialog append-to-body title="未授權使用者列表" v-model="dialogShow" width="50%">
     <!--begin::Card title-->
     <div class="row col d-flex">
                <div
                    class="
                        col-lg-3 col-12
                        d-flex
                        align-items-center
                        position-relative
                        my-1
                    "
                >
                    <span class="svg-icon svg-icon-1 position-absolute ms-6">
                        <inline-svg
                            src="media/icons/duotune/general/gen021.svg"
                        />
                    </span>
                    <input
                        type="text"
                        v-model="search"
                        @input="tableSearch()"
                        class="form-control form-control-solid ps-15"
                        placeholder="搜尋"
                    />
                </div>
            </div>
      <div class="d-flex justify-content-between">
          <div>
              <div v-if="isLoading">
                  <span
                      class="spinner-grow spinner-grow"
                      style="width: 1rem; height: 1rem"
                      role="status"
                      aria-hidden="true"
                  ></span>
                  <i style="font-size: 16px">正在載入...</i>
              </div>
          </div>
      </div>
      <Datatable
          :table-data="tableData"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="false"
          :openTextEnd="false"
          :striped="true"
      >
      <template v-slot:cell-avatar="{ row: tableData }">
          <div class="symbol symbol-55px symbol-circle">
            <img
              v-if="tableData.avatar"
              :src="getImage(tableData.avatar)"
              alt="image"
            />
            <img v-else src="media/avatars/blank.png" alt="image" />
          </div>
        </template>
          <template v-slot:cell-name="{ row: tableData }">
              <span>{{ tableData.name }}</span>
          </template>
          <template v-slot:cell-email="{ row: tableData }">
            <span>{{ tableData.email }}</span>
          </template>
          <template v-slot:cell-userIdentities="{ row: tableData }">
              <!-- <span>{{ tableData.userIdentities }}</span> -->
              <div
            class="
              symbol-group symbol-hover
              flex-nowrap flex-grow-1
              min-w-100px
              pe-2
            "
          >
            <!--begin::User-->
            <div class="symbol symbol-circle symbol-50px" v-for="userIdentity in tableData.userIdentities" :key="userIdentity">
              <img :src="getFilePath(userIdentity.identity)" alt="img" />
            </div>
          </div>
          </template>
      
          <template v-slot:cell-operate="{ row: tableData }">
              <el-dropdown
              >
                  <span class="el-dropdown-link text-primary">
                      功能選單<i
                          class="el-icon-arrow-down el-icon--right"
                      ></i>
                  </span>
                  <template #dropdown>
                      <el-dropdown-menu>
                              <el-dropdown-item @click="addUserToAccessGroup(tableData)">授權</el-dropdown-item>
                      </el-dropdown-menu>
                  </template>
              </el-dropdown>
          </template>
      </Datatable>




  </Dialog>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, inject,watch,Ref } from "vue";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import localstorage from "@/core/services/LocalstorageService";
import{
  getUseridentitiesByAccessGroupNotInGroup
} from "@/core/services/api/userIdentity";
import{
  AccessGroup
} from "@/core/services/responsesModel/accessGroup";
import {
  addAccessGroupUser
} from "@/core/services/api/accessGroup";

import { getFilePath } from "@/core/utils/file";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import {ConfirmCanncelAlert, SuccesAlert, ErrorAlert} from "@/core/utils/customSwal"
import { UserFaceData } from "./AccessGroupUserDialog.vue";
import {
  getMembersBySpace
} from "@/core/services/api/members";

export default defineComponent({
  name: "add-access-group-user-dialog",
  components: {
      Dialog,
      Datatable,
  },
  props: {
      modelValue: {
          type: Boolean,
          default: false,
      }
  },
  emits: ["update:modelValue", "onUserAdded"],
  setup(props, { emit }) {
      const dialogShow = computed({
          get: () => props.modelValue,
          set: (val) => {
              emit("update:modelValue", val);
          },
      });
      const currentAccessGroup= inject<Ref<AccessGroup>>("currentAccessGroup")!;

        const search = ref<string>("");
        const tableSearch = () => {
           
            if(search.value!=""){
              tableData.splice(0, tableData.length, ...initTableData);
              tableData.splice(
                0,
                tableData.length,
                ...searchItems(search.value, tableData)
            );
            }
            
        };
      const tableData: Array<UserFaceData> = reactive([]);
        const initTableData: Array<UserFaceData> = reactive([]);
      const tableHeader = ref([
        {
              name: "頭像",
              key: "avatar",
              sortable: true,
          },
          {
              name: "名稱",
              key: "name",
              sortable: true,
          },
          {
              name: "Email",
              key: "email",
              sortable: true,
          },
          {
              name: "人臉識別",
              key: "userIdentities",
              sortable: true,
          },
          {
              name: "操作",
              key: "operate",
              sortable: true,
          },
      ]);
      const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const addUserToAccessGroup=async (tabelData) => {
        console.log('add',tabelData);
        ConfirmCanncelAlert("您確定要授權此使用者嗎?").then(async (result) => {
                if (result.isConfirmed) {
                    const result=await addAccessGroupUser(
                        currentSpaceGuid!,
                        currentAccessGroup.value.guid,
                        tabelData.user
                    );
                    if(result){
                      await init();
                      SuccesAlert("授權成功!");
                      emit("onUserAdded");
                    }else{
                      ErrorAlert("授權失敗!");
                    }
                }
            });
            
    }
      const getImage = (guid) => {
      if (guid !== "" && guid !== null) {
        return getFilePath(guid);
      } else {
        return "";
      }
    };

      const isLoading = ref(false);
     
      const init = async () => {
        console.log('addAccessGroupUserDialog');
        if(currentAccessGroup.value.guid){
            isLoading.value = true;
          const userIdentities=await getUseridentitiesByAccessGroupNotInGroup(currentAccessGroup.value.guid);
          
          const usersGuid = userIdentities.map(o=>o.user);
          const members = await getMembersBySpace(currentSpaceGuid!);
          const membersNotInGroup= members.filter(o=>usersGuid.includes(o.guid));
          
          const data:Array<UserFaceData> =[]
            membersNotInGroup.forEach(user=> {
            data.push({
                user: user.guid,
                avatar: user.theme.avatar,
                email: user.email,
                name: user.name,
                userIdentities: userIdentities.filter(o=>o.user==user.guid)
            })
          });

          console.log('data',data);
          tableData.splice(0,tableData.length,...data);
          initTableData.splice(0,initTableData.length,...data);
          tableSearch();
          isLoading.value = false;
        }
         
      };
    
      init();
      watch(currentAccessGroup, () => {
        if(currentAccessGroup.value){
            init();
        }
        });
      return {
          dialogShow,
          tableData,
          tableHeader,
          isLoading,
          init,
          getImage,
          getFilePath,
          addUserToAccessGroup,
          tableSearch,
          search
      };
  },
});
</script>

<style scoped></style>
