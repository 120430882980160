import { UserTheme } from "./userExtend";

export class SimpleUser {

    guid: string;
    name: string;
    email:string;
    theme: UserTheme;

    constructor(data:any){
      this.guid = data.guid;
      this.name = data.name;
      this.email = data.email;
      this.theme = data.theme;
    }
}