
import { computed, defineComponent, ref, toRefs, watch } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "30%",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    top: {
      type: String,
      default: "20px",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const dialogShow = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      },
    });
    const dialogWidth = ref(props.width);

    const setWindowWidth = () => {
      if (window.innerWidth <= 1024) {
        dialogWidth.value = "100%";
      } else {
        dialogWidth.value = props.width;
      }
    };

    window.addEventListener("resize", setWindowWidth);

    return {
      ...toRefs(props),
      dialogShow,
      dialogWidth,
    };
  },
});
