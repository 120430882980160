
import { computed, defineComponent, reactive, ref, inject,watch,Ref } from "vue";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import localstorage from "@/core/services/LocalstorageService";
import{
  getUseridentitiesByAccessGroup
} from "@/core/services/api/userIdentity";
import { UserIdentity } from "@/core/services/responsesModel/userIdentity"
import{
  AccessGroup
} from "@/core/services/responsesModel/accessGroup";
import {
    deleteAccessGroupUser
} from "@/core/services/api/accessGroup";

import { getFilePath } from "@/core/utils/file";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import {ConfirmCanncelAlert, SuccesAlert, ErrorAlert} from "@/core/utils/customSwal"

export interface UserFaceData
{
    user: string;
    avatar: string;
    name: string;
    email: string;
    userIdentities: UserIdentity[];
}

export default defineComponent({
  name: "access-group-user-dialog",
  components: {
      Dialog,
      Datatable
  },
  props: {
      modelValue: {
          type: Boolean,
          default: false,
      }
  },
  emits: ["update:modelValue", "onUserRemoved","onAddUserClicked"],
  setup(props, { emit }) {
      const dialogShow = computed({
          get: () => props.modelValue,
          set: (val) => {
              emit("update:modelValue", val);
          },
      });
      const currentAccessGroup= inject<Ref<AccessGroup>>("currentAccessGroup")!;

        const search = ref<string>("");
        const tableSearch = () => {
            if(search.value!=""){
              tableData.splice(0, tableData.length, ...initTableData);
              tableData.splice(
                0,
                tableData.length,
                ...searchItems(search.value, tableData)
            );
            }
            
        };

      const tableData: Array<UserFaceData> = reactive([]);
        const initTableData: Array<UserFaceData> = reactive([]);
      const tableHeader = ref([
        {
              name: "頭像",
              key: "avatar",
              sortable: true,
          },
          {
              name: "名稱",
              key: "name",
              sortable: true,
          },
          {
              name: "Email",
              key: "email",
              sortable: true,
          },
          {
              name: "人臉識別",
              key: "userIdentities",
              sortable: true,
          },
          {
              name: "操作",
              key: "operate",
              sortable: true,
          },
      ]);
      const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const addUserClick = ()=>{
        emit('onAddUserClicked');
    }

    const removeAccessGroupUser=async (tabelData) => {
        ConfirmCanncelAlert("您確定要移除該使用者的授權嗎?").then(async (result) => {
                if (result.isConfirmed) {
                    const result=await deleteAccessGroupUser(
                        currentSpaceGuid!,
                        currentAccessGroup.value.guid,
                        tabelData.user
                    );
                    if(result){   
                        await init();
                        SuccesAlert("移除成功!");
                        emit("onUserRemoved");
                    }else{
                        ErrorAlert("移除失敗");
                    }
                }
            });
    }
      const getImage = (guid) => {
      if (guid !== "" && guid !== null) {
        return getFilePath(guid);
      } else {
        return "";
      }
    };

      const isLoading = ref(false);
     
      const init = async () => {
        if(currentAccessGroup.value.guid){
            isLoading.value = true;
          const userIdentities=await getUseridentitiesByAccessGroup(currentAccessGroup.value.guid);
          const data:Array<UserFaceData> =[]
          currentAccessGroup.value.users.forEach(user=> {
            data.push({
                user: user.guid,
                avatar: user.theme.avatar,
                email: user.email,
                name: user.name,
                userIdentities: userIdentities.filter(o=>o.user==user.guid)
            })
          });
          tableData.splice(0,tableData.length,...data);
          initTableData.splice(0,initTableData.length,...data)
          isLoading.value = false;
        }
         
      };
    
      init();
      watch(currentAccessGroup, () => {
        if(currentAccessGroup.value){
            init();
        }
        });
      return {
          dialogShow,
          tableData,
          tableHeader,
          isLoading,
          init,
          getImage,
          getFilePath,
          removeAccessGroupUser,
          tableSearch,
          search,
          addUserClick
      };
  },
});
