<template>
    <div class="card">
        <div class="card-header border-0 pt-6 row">
            <!--begin::Card title-->
            <div class="row col d-flex">
                <div
                    class="
                        col-lg-3 col-12
                        d-flex
                        align-items-center
                        position-relative
                        my-1
                    "
                >
                    <span class="svg-icon svg-icon-1 position-absolute ms-6">
                        <inline-svg
                            src="media/icons/duotune/general/gen021.svg"
                        />
                    </span>
                    <input
                        type="text"
                        v-model="search"
                        @input="tableSearch()"
                        class="form-control form-control-solid ps-15"
                        placeholder="搜尋"
                    />
                </div>
            </div>
        </div>
        <div class="card-body pt-0">
            <div v-if="isLoading">
                <span
                    class="spinner-grow spinner-grow"
                    style="width: 1rem; height: 1rem"
                    role="status"
                    aria-hidden="true"
                ></span>
                <i style="font-size: 16px">正在載入...</i>
            </div>
            <Datatable
                :table-data="tableData"
                :table-header="tableHeader"
                :enable-items-per-page-dropdown="true"
                :openTextEnd="false"
                :striped="true"
            >
                <template v-slot:cell-name="{ row: tableData }">
                    {{ tableData.name }}
                </template>
                <template v-slot:cell-accessDevices="{ row: tableData }">
                    <span class="badge badge-light" v-for="accessDevice in tableData.accessDevices" :key="accessDevice.guid">{{
                        accessDevice.name
                    }}</span>
                </template>
                <template v-slot:cell-users="{ row: tableData }">
                  <a href="javascript:void(0)"  @click="openAccessGroupUserDialog(tableData)">{{ tableData.users.length }}</a>
                </template>
                <template v-slot:cell-operate="{ row: tableData }">
                    <el-dropdown>
                        <span
                            @mouseover="setcurrentAccessGroup(tableData)"
                            class="el-dropdown-link text-primary"
                        >
                            功能選單<i
                                class="el-icon-arrow-down el-icon--right"
                            ></i>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                              <el-dropdown-item @click="handleAddUserClick()"
                                    >新增使用者</el-dropdown-item
                                >
                                <el-dropdown-item @click="removeAccessGroup()"
                                    >刪除</el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </Datatable>
        </div>
    </div>

    <AccessGroupUserDialog
      v-model="accessGroupDialogVisiable"
      @onUserRemoved="reload()"
      @onAddUserClicked="handleAddUserClick()"
    > </AccessGroupUserDialog>
    <AddAccessGroupUserDialog 
      v-model="addAccessGroupUserDialogVisiable" 
      @onUserAdded="reload()">
      </AddAccessGroupUserDialog>
</template>

<script lang="ts">
import { defineComponent, reactive, ref,provide } from "vue";
import {
    getAccessGroup,
    deleteAccessGroup,
    
} from "@/core/services/api/accessGroup";
import{
  DeaultAccessGroup,
  AccessGroup
} from "@/core/services/responsesModel/accessGroup";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { ConfirmCanncelAlert, SuccesAlert, ErrorAlert } from "@/core/utils/customSwal"
import AccessGroupUserDialog from "./components/AccessGroupUserDialog.vue"
import AddAccessGroupUserDialog from "./components/AddAccessGroupUserDialog.vue"

export default defineComponent({
    components: {
        Datatable,
        AccessGroupUserDialog,
        AddAccessGroupUserDialog 
    },
    setup() {
        const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
        const currentAccessGroup = ref<AccessGroup>(DeaultAccessGroup);
        const tableData: Array<AccessGroup> = reactive([]);
        const initTableData: Array<AccessGroup> = reactive([]);
        
        const isLoading = ref(false);
        const accessGroupDialogVisiable = ref(false);
        const tableHeader: any = reactive([
            { key: "name", name: "名稱" },
            { key: "accessDevices", name: "通行裝置名稱", sortable: true },
            { key: "users", name: "已設定人數", sortable: true },
            { key: "operate", name: "操作" }
        ]);
        const search = ref<string>("");
        const tableSearch = () => {
            tableData.splice(0, tableData.length, ...initTableData);
            tableData.splice(
                0,
                tableData.length,
                ...searchItems(search.value, tableData)
            );
        };
        provide("currentAccessGroup", currentAccessGroup);
        const setcurrentAccessGroup = (item) => {
          currentAccessGroup.value=item;
        };

        const removeAccessGroup = async () => {
          
          ConfirmCanncelAlert("您確定要刪除通行裝置群組嗎?",).then(async (result) => {
                if (result.isConfirmed) {
                    const result=await deleteAccessGroup(
                        currentSpaceGuid!,
                        currentAccessGroup.value.guid
                    );
                    if(result){
                      await init();
                      SuccesAlert("刪除成功!");
                    }else{
                      ErrorAlert("刪除失敗!");
                    }
                }
            });
        };

        const setTableData = async () => {
            isLoading.value = true;
            let accessGroups = await getAccessGroup(currentSpaceGuid!);
            tableData.splice(0, tableData.length, ...accessGroups);
            isLoading.value = false;
        };
        const openAccessGroupUserDialog= (item) => {

          accessGroupDialogVisiable.value=true;
          currentAccessGroup.value = item;
        }
        const addAccessGroupUserDialogVisiable = ref(false);
        const handleAddUserClick=()=>{
          accessGroupDialogVisiable.value = false;
          addAccessGroupUserDialogVisiable.value=true;

        }
        const init = async () => {
            await setTableData();
            
        };
        init();
        const reload=async()=>{
          await init();
          currentAccessGroup.value = tableData.filter(o=>o.guid==currentAccessGroup.value.guid)[0]
        }

        return {
            tableData,
            tableHeader,
            initTableData,
            tableSearch,
            search,
            isLoading,
            setcurrentAccessGroup,
            currentAccessGroup,
            removeAccessGroup,
            accessGroupDialogVisiable,
            openAccessGroupUserDialog ,
            addAccessGroupUserDialogVisiable,
            reload,
            handleAddUserClick
        };
    },
});
</script>

<style></style>
