import ApiService from "../ApiService"
import { UserIdentity } from "../responsesModel/userIdentity"

export function getUseridentitiesByAccessGroup(accessGroupGuid): Promise<Array<UserIdentity>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`AccessGroup/${accessGroupGuid}/UserIdentity/FaceImage`).then(res => {
            const userIdentities:Array<UserIdentity> =[]
            res.data.forEach(o=>{
              userIdentities.push(new UserIdentity(o))
            })
            resolve(userIdentities)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getUseridentitiesByAccessGroupNotInGroup(accessGroupGuid): Promise<Array<UserIdentity>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`AccessGroup/${accessGroupGuid}/UserIdentity/FaceImage/NotInGroup`).then(res => {
            const userIdentities:Array<UserIdentity> =[]
            res.data.forEach(o=>{
              userIdentities.push(new UserIdentity(o))
            })
            resolve(userIdentities)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getUseridentitiesBySpace(spaceGuid): Promise<Array<UserIdentity>> {
  return new Promise((resolve, reject) => {
      ApiService.get(`Space/${spaceGuid}/UserIdentity/FaceImage`).then(res => {
          const userIdentities:Array<UserIdentity> =[]
          res.data.forEach(o=>{
            userIdentities.push(new UserIdentity(o))
          })
          resolve(userIdentities)
      }).catch(err => {
          reject(err)
      })
  })
}