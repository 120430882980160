
import { computed, defineComponent, reactive, ref, inject,watch,Ref } from "vue";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import localstorage from "@/core/services/LocalstorageService";
import{
  getUseridentitiesByAccessGroupNotInGroup
} from "@/core/services/api/userIdentity";
import{
  AccessGroup
} from "@/core/services/responsesModel/accessGroup";
import {
  addAccessGroupUser
} from "@/core/services/api/accessGroup";

import { getFilePath } from "@/core/utils/file";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import {ConfirmCanncelAlert, SuccesAlert, ErrorAlert} from "@/core/utils/customSwal"
import { UserFaceData } from "./AccessGroupUserDialog.vue";
import {
  getMembersBySpace
} from "@/core/services/api/members";

export default defineComponent({
  name: "add-access-group-user-dialog",
  components: {
      Dialog,
      Datatable,
  },
  props: {
      modelValue: {
          type: Boolean,
          default: false,
      }
  },
  emits: ["update:modelValue", "onUserAdded"],
  setup(props, { emit }) {
      const dialogShow = computed({
          get: () => props.modelValue,
          set: (val) => {
              emit("update:modelValue", val);
          },
      });
      const currentAccessGroup= inject<Ref<AccessGroup>>("currentAccessGroup")!;

        const search = ref<string>("");
        const tableSearch = () => {
           
            if(search.value!=""){
              tableData.splice(0, tableData.length, ...initTableData);
              tableData.splice(
                0,
                tableData.length,
                ...searchItems(search.value, tableData)
            );
            }
            
        };
      const tableData: Array<UserFaceData> = reactive([]);
        const initTableData: Array<UserFaceData> = reactive([]);
      const tableHeader = ref([
        {
              name: "頭像",
              key: "avatar",
              sortable: true,
          },
          {
              name: "名稱",
              key: "name",
              sortable: true,
          },
          {
              name: "Email",
              key: "email",
              sortable: true,
          },
          {
              name: "人臉識別",
              key: "userIdentities",
              sortable: true,
          },
          {
              name: "操作",
              key: "operate",
              sortable: true,
          },
      ]);
      const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const addUserToAccessGroup=async (tabelData) => {
        console.log('add',tabelData);
        ConfirmCanncelAlert("您確定要授權此使用者嗎?").then(async (result) => {
                if (result.isConfirmed) {
                    const result=await addAccessGroupUser(
                        currentSpaceGuid!,
                        currentAccessGroup.value.guid,
                        tabelData.user
                    );
                    if(result){
                      await init();
                      SuccesAlert("授權成功!");
                      emit("onUserAdded");
                    }else{
                      ErrorAlert("授權失敗!");
                    }
                }
            });
            
    }
      const getImage = (guid) => {
      if (guid !== "" && guid !== null) {
        return getFilePath(guid);
      } else {
        return "";
      }
    };

      const isLoading = ref(false);
     
      const init = async () => {
        console.log('addAccessGroupUserDialog');
        if(currentAccessGroup.value.guid){
            isLoading.value = true;
          const userIdentities=await getUseridentitiesByAccessGroupNotInGroup(currentAccessGroup.value.guid);
          
          const usersGuid = userIdentities.map(o=>o.user);
          const members = await getMembersBySpace(currentSpaceGuid!);
          const membersNotInGroup= members.filter(o=>usersGuid.includes(o.guid));
          
          const data:Array<UserFaceData> =[]
            membersNotInGroup.forEach(user=> {
            data.push({
                user: user.guid,
                avatar: user.theme.avatar,
                email: user.email,
                name: user.name,
                userIdentities: userIdentities.filter(o=>o.user==user.guid)
            })
          });

          console.log('data',data);
          tableData.splice(0,tableData.length,...data);
          initTableData.splice(0,initTableData.length,...data);
          tableSearch();
          isLoading.value = false;
        }
         
      };
    
      init();
      watch(currentAccessGroup, () => {
        if(currentAccessGroup.value){
            init();
        }
        });
      return {
          dialogShow,
          tableData,
          tableHeader,
          isLoading,
          init,
          getImage,
          getFilePath,
          addUserToAccessGroup,
          tableSearch,
          search
      };
  },
});
