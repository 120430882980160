import { AccessDevice, VendorType } from "./accessDevice";
import { SimpleUser } from "./simpleUser";

export class AccessGroup {
    guid: string;
    space: string;
    vendor: VendorType;
    name: string;
    accessDeviceGroupId: string;
    accessDevices: AccessDevice[];
    users: SimpleUser[];
    createTime: string;

    constructor(data: any) {
        this.guid = data.guid;
        this.space = data.space;
        this.vendor = data.vendor;
        this.name = data.name;
        this.accessDeviceGroupId = data.accessDeviceGroupId;
        const accessDevices: AccessDevice[] = [];
        data.accessDevices.forEach((o) =>
            accessDevices.push(new AccessDevice(o))
        );
        this.accessDevices = accessDevices;
        const users: SimpleUser[] = [];
        data.users.forEach((o) => users.push(new SimpleUser(o)));
        this.users = users;

        this.createTime = data.createTime;
    }
}

export  const DeaultAccessGroup= {
  guid: "",
  space: "",
  vendor: VendorType.CloudMatrix,
  name: "",
  accessDeviceGroupId: "",
  accessDevices:[],
  users: [],
  createTime: ""
}
