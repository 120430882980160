<template>
  <div class="v-dialog dialog100" :class="[$slots.footer && 'dialog160']">
    <el-dialog
      custom-class="dialog"
      :title="title"
      v-model="dialogShow"
      :append-to-body="false"
      :width="dialogWidth"
      :top="top"
    >
      <slot></slot>

      <template v-if="$slots.footer" #footer>
        <slot name="footer"></slot>
      </template>
    </el-dialog>
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent, ref, toRefs, watch } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "30%",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    top: {
      type: String,
      default: "20px",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const dialogShow = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      },
    });
    const dialogWidth = ref(props.width);

    const setWindowWidth = () => {
      if (window.innerWidth <= 1024) {
        dialogWidth.value = "100%";
      } else {
        dialogWidth.value = props.width;
      }
    };

    window.addEventListener("resize", setWindowWidth);

    return {
      ...toRefs(props),
      dialogShow,
      dialogWidth,
    };
  },
});
</script>


<style  scoped>
.v-dialog >>> .el-dialog {
  border-radius: 0.475rem;
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}
.v-dialog >>> .el-dialog__header {
  border-bottom: 1px solid #eff2f5;
}
.v-dialog >>> .el-dialog__body {
  overflow: auto;
  padding: 0;
  margin: 15px 20px;
}
.v-dialog >>> .el-dialog__footer {
  border-top: 1px solid #eff2f5;
  height: 80px;
}
.v-dialog >>> .el-overlay {
  z-index: 1000 !important;
}
.dialog160 >>> .el-dialog__body {
  max-height: calc(100vh - 160px) !important;
}
.dialog100 >>> .el-dialog__body {
  max-height: calc(100vh - 100px);
}
</style>