import ApiService from "@/core/services/ApiService";
import { AccessGroup } from "../responsesModel/accessGroup";

// 查詢空間所屬的臉部辨識通行群組
export function getAccessGroup(spaceGuid: string): Promise<Array<AccessGroup>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/AccessGroup`).then(res => {
            const data:Array<AccessGroup> = []
            res.data.forEach(o => {
                data.push(new AccessGroup(o))
            }
                );
            resolve(data);
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增空間所屬的臉部辨識通行群組
export function addAccessGroup(spaceGuid: string, request): Promise<AccessGroup> {
  return new Promise((resolve, reject) => {
      ApiService.post(`Space/${spaceGuid}/AccessGroup`, request).then(res => {
          resolve(new AccessGroup(res.data))
      }).catch(err => {
          reject(err)
      })
  })
}

// 刪除空間所屬的臉部辨識通行群組
export function deleteAccessGroup(spaceGuid: string,accessGroupGuid :string): Promise<boolean> {
  return new Promise((resolve, reject) => {
      ApiService.delete(`Space/${spaceGuid}/AccessGroup/${accessGroupGuid}`).then(res => {
          resolve(res.data.result)
      }).catch(err => {
          reject(err)
      })
  })
}

// 新增使用者所屬的通行群組
export function addAccessGroupUser(spaceGuid: string,accessGroupGuid :string, userGuid:string ): Promise<AccessGroup> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/AccessGroup/${accessGroupGuid}/AccessGroupUserMapping/User/${userGuid}`,{}).then(res => {
            resolve(new AccessGroup(res.data))
        }).catch(err => {
            reject(err)
        })
    })
  }
// 刪除使用者所屬的通行群組
export function deleteAccessGroupUser(spaceGuid: string,accessGroupGuid :string, userGuid:string ) : Promise<boolean>{
    return new Promise((resolve, reject) => {
        ApiService.delete(`Space/${spaceGuid}/AccessGroup/${accessGroupGuid}/AccessGroupUserMapping/User/${userGuid}`).then(res => {
            resolve(res.data.result)
        }).catch(err => {
            reject(err)
        })
    })
  }